import { memo, useEffect, useState } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import { stripeStyle } from '../../../styles/stripeStyle'
import axios from 'axios'
import fetchData from '../../../hook/fetchData'
import Loader from '../../Loader'
import { getEnv } from "@urecruits/api"
import { useHasPermission, AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'

const visaIc = require('../../../image/icon/visa_small_ic.svg')
const mastercardIc = require('../../../image/icon/mastercard_small_ic.svg')
const americanExpressIcon = require('../../../image/icon/aexpress_icon.svg')
const discoverIcon = require('../../../image/icon/discover_icon.svg')
const dinnersIcon = require('../../../image/icon/diners_ic.svg')
const jcbIcon = require('../../../image/icon/jcb_ic.svg')
const unionIcon = require('../../../image/icon/union_icon.svg')
const successImage = require('../../../image/icon/success_image.svg')

const PaymentSystemPopup = ({ subscriptionData, setSubscriptionData, setRefetchSubscriptionData, setTabDisplay }) => {
  const {checkUserPermission} = useHasPermission()
  const [paymentLengthError, setPaymentLengthError] = useState(false)
  const [stripeError, setStripeError] = useState(null)
  const [payErrorText, setPayErrorText] = useState('')
  const [loader, setLoader] = useState(false)
  const [step, setStep] = useState('choosePayMethod')
  const [refetch, setRefetch] = useState('')
  const [paymentMethods, setPaymentMethods] = useState([])

  //init stripe
  const stripe = useStripe()
  const elements = useElements()

  const onClosePopup = (e) => {
    e.preventDefault()
    setSubscriptionData({ ...subscriptionData, popupVisible: false })
  }

  //pay now action/create subscribe
  const onChoosePaymentMethodStep = async (e) => {
    e.preventDefault()
    setLoader(true)
    await subscribeHandler(subscriptionData.paymentMethodId)
  }
  const {API_RECRUITMENT} = getEnv()

  //reuse func for added/change subscription
  const subscribeHandler = (paymentId) => {
    postHandler(`${API_RECRUITMENT}/api/payment/create-subscription`,
      {
        paymentMethodId: paymentId,
        priceId: subscriptionData.priceId,
      }).then((res) => {
      if (res.data.status == 'active') {
        setLoader(false)
        setStep('successfulSubscription')
        setPayErrorText('')
        //if we have 3d secure case processing it
      } else if (res.data.piStatus === 'requires_action') {
        validate3DSecure(res)
      }
    })
  }

  //template post handler
  const postHandler = async (link, obj) => {
    const token: string = localStorage.getItem('token')

    return await axios.post(link, obj, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
  }

  //validate 3d card secure, if have error repeat send
  const validate3DSecure = (res) => {
    //3D secure case
    stripe.confirmCardPayment(res.data.piCs, {
      payment_method: subscriptionData.paymentMethodId,
    }).then((result) => {
      if (result.error) {
        //if pay not valid or cancel set error
        setPayErrorText('Error confirmCardPayment')
      } else {
        //when subscribe change status to success, create new request
        if (result.paymentIntent.status === 'succeeded') {
          //trigger new request with subscriptionId product when we have pay error or 3d secure
          postHandler(`${API_RECRUITMENT}/api/payment/create-subscription`, {
            paymentMethodId: subscriptionData.paymentMethodId,
            subscriptionId: res.data.subId,
          }).then((response) => {
            //when request success trigger popup and clear loader
            if (response.data.status === 'active') {
              setLoader(false)
              setStep('successfulSubscription')
              setPayErrorText('')
            } else {
              //if we have error set message
              setPayErrorText('Error confirmCardPayment')
            }
          })
        } else {
          //if we have error set message
          setPayErrorText('Error confirmCardPayment')
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  //when all success close popup,refetch and display firs screen step
  const onCloseSuccessStep = () => {
    setSubscriptionData({ ...subscriptionData, popupVisible: false })
    setRefetchSubscriptionData(new Date())
    setTabDisplay('current')
  }

  //Added new payment method and create subscribe
  const onSubmitNewCartAddedForm = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }
    if (paymentMethods.length <= 9) {
      try {
        postHandler(`${API_RECRUITMENT}/api/payment/create-setup-intent`, {}).then((response) => {
          //stripe create confirm card
          stripe.confirmCardSetup(response.data, {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
          }).then(function (result) {
            if (result.error) {
              //stripe form validate data
              setStripeError(result.error)
            } else {
              setLoader(true)
              //when card create added subscription
              subscribeHandler(result.setupIntent.payment_method)
            }
          })
        })
      } catch (e) {
        console.log(e)
      }
    } else {
      setPaymentLengthError(true)
    }
  }

  useEffect(() => {
    if(checkUserPermission('subscription','view')){
    setLoader(true)
    fetchData(`${API_RECRUITMENT}/api/payment/get-payment-methods`, setPaymentMethods).then((res) => {
      //if res.data empty display create payment method form
      res.data.length === 0 && (setStep('createPayMethod'))
      setLoader(false)
    })
  }
  }, [refetch])

  //set default payment card id, need for get active card
  useEffect(() => {
    if (paymentMethods[0]) {
      const findDefaultCard = paymentMethods.find(item => item.default == true)
      setSubscriptionData({ ...subscriptionData, paymentMethodId: findDefaultCard.id })
    }
  }, [paymentMethods])

  return (
    <AuthGuard module={['payment-integration','subscription']} permission={['add','edit']}>
    <div className="popup">
      {
        step === 'createPayMethod'&& checkUserPermission('subscription','add') && (
          <form className="popup__step add-payment-method-form" onSubmit={onSubmitNewCartAddedForm}>
            <div className="popup__step__head">
              <p className="popup__step__headline">
                Payment System
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="popup__step__close"
                onClick={onClosePopup}
              >
                <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div className="popup__step__body">
              <div className="popup__stripe">
                <p className="popup__stripe__headline">
                  Enter your card details for payment
                </p>
                <div className="popup__stripe__inner">
                  <div className="popup__stripe__group">
                    <div className={`popup__stripe__item ${stripeError && stripeError.code === 'incomplete_number' ? 'error' : ''}`}>
                      <label className="popup__stripe__label">
                        Card Number
                      </label>
                      <div className="popup__stripe__container">
                        <CardNumberElement
                          options={stripeStyle}
                        />
                      </div>
                      <p className="error-message">
                        {stripeError && (stripeError.message)}
                      </p>
                    </div>
                  </div>
                  <div className="popup__stripe__group">
                    <div className={`payment__form__item half ${stripeError && stripeError.code === 'incomplete_expiry' ? 'error' : ''}`}>
                      <label className="popup__stripe__label">
                        Month/Year
                      </label>
                      <div className="popup__stripe__container">
                        <CardExpiryElement
                          options={stripeStyle}
                        />
                      </div>
                      <p className="error-message">
                        {stripeError && (stripeError.message)}
                      </p>
                    </div>
                    <div className={`payment__form__item half ${stripeError && stripeError.code === 'incomplete_cvc' ? 'error' : ''}`}>
                      <label className="popup__stripe__label">
                        CVV Code
                      </label>
                      <div className="popup__stripe__container">
                        <CardCvcElement
                          options={stripeStyle}
                        />
                      </div>
                      <p className="error-message">
                        {stripeError && (stripeError.message)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {loader && (
                <Loader/>
              )}
            </div>
            <div className="popup__step__control end">
              <button
                className="popup__step__button button--filled"
                type="submit"
                disabled={!stripe}

              >
                Pay now
              </button>
              {
                payErrorText.length > 0 && (
                  <p className="error-message">{payErrorText}</p>
                )
              }
              {
                stripeError && stripeError.code === 'card_declined' && (
                  <p className="error-message">{stripeError.message}</p>
                )
              }
              {
                paymentLengthError && (
                  <p className="error-message"> You have exceeded the card limit. Delete a card to add a new one.</p>
                )
              }
            </div>
          </form>
        )
      }
      {
        step === 'choosePayMethod' && (
          <div className="popup__step">
            <div className="popup__step__head">
              <p className="popup__step__headline">
                Current Plan
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="popup__step__close"
                onClick={onClosePopup}
              >
                <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div className="popup__step__body">
              <div className="popup__payment">
                <p className="popup__payment__headline cards">
                  Choose pay method
                </p>
                <ul className="popup__payment__list">
                  {loader ?
                    <Loader/>
                    : paymentMethods.map(item => {
                      return (
                        <li
                          className={`popup__payment__item ${subscriptionData.paymentMethodId === item.id ? 'active' : ''} ${item.default ? 'default' : ''}`}
                          key={item.id}
                          onClick={() => setSubscriptionData({ ...subscriptionData, paymentMethodId: item.id })}
                        >
                          {item.brand === 'visa' ? <img src={visaIc} alt="card type" className="popup__payment__type"/> : null}
                          {item.brand === 'mastercard' ? <img src={mastercardIc} alt="card type" className="popup__payment__type"/> : null}
                          {item.brand === 'amex' ? <img src={americanExpressIcon} alt="card type" className="popup__payment__type"/> : null}
                          {item.brand === 'discover' ? <img src={discoverIcon} alt="card type" className="popup__payment__type"/> : null}
                          {item.brand === 'unionpay' ? <img src={unionIcon} alt="card type" className="popup__payment__type"/> : null}
                          {item.brand === 'diners' ? <img src={dinnersIcon} alt="card type" className="popup__payment__type"/> : null}
                          {item.brand === 'jcb' ? <img src={jcbIcon} alt="card type" className="popup__payment__type"/> : null}
                          <p className="popup__payment__card">
                            XXXX XXXX XXXX<span> {item.last4}</span>
                          </p>
                          <div className="popup__payment__radio"></div>
                        </li>
                      )
                    })
                  }
                </ul>
                <AuthGuard module='subscription' permission='add'>
                <div className="popup__payment__another" onClick={() => setStep('createPayMethod')}>
                  Add another payment method
                </div>
                </AuthGuard>
              </div>
              {loader && (
                <Loader/>
              )}
            </div>
            <div className="popup__step__control end">
              <div
                className="popup__step__button button--filled"
                onClick={onChoosePaymentMethodStep}
              >
                Pay now
              </div>
              {
                payErrorText.length > 0 && (
                  <p className="error-message">{payErrorText}</p>
                )
              }
            </div>
          </div>
        )
      }
      {
        step === 'successfulSubscription' && (
          <div className="popup__step">
            <div className="popup__step__head">
              <p className="popup__step__headline">
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="popup__step__close"
                onClick={onClosePopup}
              >
                <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div className="popup__step__body">
              <div className="popup__success">
                <img src={successImage} alt="success image" className="popup__success__image"/>
                <p className="popup__success__title">
                  Successful subscription renewal
                </p>
              </div>
            </div>
            <div className="popup__step__control center">
              <div
                className="popup__step__button button--filled"
                onClick={onCloseSuccessStep}
              >
                Continue!
              </div>
            </div>
          </div>
        )
      }
    </div>
    </AuthGuard>
  )
}

export default memo(PaymentSystemPopup)