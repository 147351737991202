import SubscriptionCard from './SubscriptionCard'
import { memo, useEffect, useState } from 'react'
import fetchData from '../../../hook/fetchData'
import PaymentSystemPopup from './PaymentSystemPopup'
import CancelSubscriptionPopup from './CancelSubscriptionPopup'
import { getEnv } from "@urecruits/api"
import { useHasPermission, AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'

const noneSubscriptionImg = require('../../../image/none_subscription.svg')

const SubscriptionTab = () => {
  const {checkUserPermission} = useHasPermission()
  //data for PaymentSystemPopup(for update subscription plan)
  const [subscriptionData, setSubscriptionData] = useState({
    popupVisible: false,
    paymentMethodId: '',
    priceId: '',
    subscriptionId: '',
  })

  const [cancelSubscriptionData, setCancelSubscriptionData] = useState({
    subId: '',
    popupVisible: false,
  })

  //tabs display action
  const [period, setPeriod] = useState('monthly')
  const [activePackage, setActivePackage] = useState('ATSPackage')
  const [tabDisplay, setTabDisplay] = useState('current')

  const [plans, setPlans] = useState(null)
  const [currentSubscription, setCurrentSubscription] = useState(null)
  const [refetch, setRefetch] = useState('')
  const {API_RECRUITMENT} = getEnv()

  //set all plans data and current subscription
  useEffect(() => {
    checkUserPermission('subscription','view') && fetchData(`${API_RECRUITMENT}/api/payment/get-plans`, setPlans).then()
    checkUserPermission('subscription','view') && fetchData(`${API_RECRUITMENT}/api/payment/get-company-subscription`, setCurrentSubscription).then()
  }, [refetch])

  //convert Date
  const convertData = (value) => {
    return new Date(value * 1000).toLocaleDateString('en-US')
  }

  //calc subscription day left
  const subscriptionDayLeft = (currentDay, periodEndDay) => {
    const date1 = new Date(currentDay)
    const date2 = new Date(periodEndDay * 1000)
    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24
    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime()

    return Math.round(diffInTime / oneDay)
  }

  //Find current subscription plan
  const findCurrentSubscriptionPlan = () => {
    if (plans && currentSubscription) {
      const atsFindResult = plans.ATS.find(item => item.id == currentSubscription.productId)
      const assessmentFindResult = plans.Assessment.find(item => item.id == currentSubscription.productId)

      return atsFindResult || assessmentFindResult
    }
  }

  //set subId
  useEffect(() => {
    if (currentSubscription) {
      currentSubscription.status !== 'active' && (setTabDisplay('subscriptionNone'))
      setSubscriptionData({ ...subscriptionData, subscriptionId: currentSubscription.subId })
    }
  }, [currentSubscription])

  return (
    <AuthGuard module='subscription' permission={['view','add','edit','delete']}>
      <div className="profile__right__inner">
        <div className="subscription">
          {
            tabDisplay === 'current' && (
              <>
                <div className="profile__head">
                  <div className="profile__head__inner">
                    <p className="profile__head__title">
                      Subscriptions
                    </p>
                  </div>
                </div>
                <div className="subscription__current">
                  <div className="subscription__current__head">
                    <div className="subscription__current__left">
                      {
                        currentSubscription && (
                          <p className="subscription__current__title">
                            Current subscriptions - <span>{`${currentSubscription.packageName} Package`} ({currentSubscription.planName})</span>
                          </p>
                        )
                      }
                      {
                        currentSubscription && currentSubscription.subId === 'trial' && (
                          <p className="subscription__current__description">
                            You now have a free trial activated. You can upgrade to a paid subscription at any time.
                          </p>
                        )
                      }
                      {
                        currentSubscription && currentSubscription.subId !== 'trial' && (
                          <p className="subscription__current__description">
                            You have an active subscription. You can change it at any time.
                          </p>
                        )
                      }
                    </div>
                    <div className="subscription__current__right">
                      <ul className="subscription__current__list">
                        {
                          checkUserPermission('subscription','view') && currentSubscription && currentSubscription.subId === 'trial' && (
                            <>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Package:
                                </p>
                                <p className="subscription__current__item--value">
                                  {`${currentSubscription.packageName} Package`}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Tier:
                                </p>
                                <p className="subscription__current__item--value">
                                  {currentSubscription.planName}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Trial active to:
                                </p>
                                <p className="subscription__current__item--value">
                                  {convertData(currentSubscription.currentPeriodEnd)}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Days untial due:
                                </p>
                                <p className="subscription__current__item--value">
                                  {subscriptionDayLeft(Date.now(), currentSubscription.currentPeriodEnd)}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Status:
                                </p>
                                <p className="subscription__current__item--value">
                                  {currentSubscription.status}
                                </p>
                              </li>
                            </>
                          )
                        }
                        {
                          checkUserPermission('subscription','view') && currentSubscription && currentSubscription.subId !== 'trial' && (
                            <>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Package:
                                </p>
                                <p className="subscription__current__item--value">
                                  {`${currentSubscription.packageName} Package`}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Tier:
                                </p>
                                <p className="subscription__current__item--value">
                                  {currentSubscription.planName}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Card:
                                </p>
                                <p className="subscription__current__item--value">
                                  {`**** **** **** ${currentSubscription.last4}`}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Next payment:
                                </p>
                                <p className="subscription__current__item--value">
                                  {convertData(currentSubscription.currentPeriodEnd)}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Status:
                                </p>
                                <p className="subscription__current__item--value">
                                  {currentSubscription.status}
                                </p>
                              </li>
                            </>
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="subscription__current__body">
                    {
                      checkUserPermission('subscription','view') && plans && currentSubscription && (
                        <SubscriptionCard
                          setTabDisplay={setTabDisplay}
                          tabDisplay={tabDisplay}
                          cardData={findCurrentSubscriptionPlan()}
                          period={period}
                          setSubscriptionData={setSubscriptionData}
                          subscriptionData={subscriptionData}
                          currentSubscription={currentSubscription}
                          cancelSubscriptionData={cancelSubscriptionData}
                          setCancelSubscriptionData={setCancelSubscriptionData}
                        />
                      )
                    }
                  </div>
                </div>
              </>
            )
          }
          {
            tabDisplay == 'packages' && checkUserPermission('subscription','view') &&  (
              <div className="packages">
                <p className="packages__back" onClick={() => setTabDisplay('current')}>
                  Back
                </p>
                <div className="packages__head">
                  <div className="packages__head__left">
                    <p className="packages__head__title">
                      Subscriptions
                    </p>
                    <div className="packages__menu">
                      <div
                        className={`packages__menu__item ${activePackage == 'ATSPackage' ? 'active' : ''}`}
                        onClick={() => setActivePackage('ATSPackage')}
                      >
                        ATS Package
                      </div>
                      <div
                        className={`packages__menu__item ${activePackage == 'AssessmentPackage' ? 'active' : ''}`}
                        onClick={() => setActivePackage('AssessmentPackage')}
                      >
                        Assessment Package
                      </div>
                    </div>
                  </div>
                  <div className="packages__head__right">
                    <form className="packages__period">
                      <div className="packages__period__item">
                        <input
                          type="radio"
                          name="period"
                          id="monthly"
                          className="packages__period__checkbox"
                          value="monthly"
                          onChange={() => setPeriod('monthly')}
                          checked={period === 'monthly'}
                        />
                        <label htmlFor="monthly" className="packages__period__label">Monthly</label>
                      </div>
                      <div className="packages__period__item">
                        <input
                          type="radio"
                          className="packages__period__checkbox"
                          name="period"
                          id="yearly"
                          value="yearly"
                          onChange={() => setPeriod('yearly')}
                          checked={period === 'yearly'}
                        />
                        <label htmlFor="yearly" className="packages__period__label">Yearly</label>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="packages__body">
                  {activePackage === 'ATSPackage' ?
                    plans && plans.ATS.map(item => {
                      return (
                        <SubscriptionCard
                          setTabDisplay={setTabDisplay}
                          tabDisplay={tabDisplay}
                          key={item.id}
                          cardData={item}
                          period={period}
                          setSubscriptionData={setSubscriptionData}
                          subscriptionData={subscriptionData}
                          currentSubscription={currentSubscription}
                          cancelSubscriptionData={cancelSubscriptionData}
                          setCancelSubscriptionData={setCancelSubscriptionData}
                        />
                      )
                    })
                    : null
                  }
                  {activePackage === 'AssessmentPackage' ?
                    plans && plans.Assessment.map(item => {
                      return (
                        <SubscriptionCard
                          setTabDisplay={setTabDisplay}
                          tabDisplay={tabDisplay}
                          key={item.id}
                          cardData={item}
                          period={period}
                          setSubscriptionData={setSubscriptionData}
                          subscriptionData={subscriptionData}
                          currentSubscription={currentSubscription}
                          cancelSubscriptionData={cancelSubscriptionData}
                          setCancelSubscriptionData={setCancelSubscriptionData}
                        />
                      )
                    })
                    : null
                  }
                </div>
              </div>
            )
          }
          {
            tabDisplay === 'subscriptionNone' && (
              <>
                <div className="profile__head">
                  <div className="profile__head__inner">
                    <p className="profile__head__title">
                      Subscriptions
                    </p>
                  </div>
                </div>
                <div className="subscription__empty">
                  <div className="subscription__empty__head">
                    <p className="subscription__empty__title">
                      Current subscriptions - <span>None</span>
                    </p>
                    <p className="subscription__empty__description">
                      You have an active subscription. You can change it at any time.
                    </p>
                  </div>
                  <AuthGuard module='subscription' permission={['add','edit']}>
                  <div className="subscription__empty__body">
                    <img src={noneSubscriptionImg} alt="subscription__empty__image"/>
                    <div className="subscription__empty__button button--filled">Choose your plan</div>
                  </div>
                  </AuthGuard>
                </div>
              </>
            )
          }
        </div>
      </div>
      {
        subscriptionData.popupVisible && checkUserPermission('subscription',['add','edit'])&& (
          <PaymentSystemPopup
            subscriptionData={subscriptionData}
            setSubscriptionData={setSubscriptionData}
            setRefetchSubscriptionData={setRefetch}
            setTabDisplay={setTabDisplay}
          />
        )
      }
      {
        cancelSubscriptionData.popupVisible && checkUserPermission('subscription','delete') && (
          <CancelSubscriptionPopup
            setRefetch={setRefetch}
            title="Cancel subscription"
            description="Do you really want to cancel the subscription?"
            setCancelSubscriptionData={setCancelSubscriptionData}
            cancelSubscriptionData={cancelSubscriptionData}
          />
        )
      }
    </AuthGuard>
  )
}

export default memo(SubscriptionTab)