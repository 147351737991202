import {memo, useState} from 'react'
import { default as ReactSelect } from 'react-select'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import { selectCustomStyle } from '../../../styles/selectCustomStyle'
import { stripeStyle } from '../../../styles/stripeStyle'
import axios from 'axios'
import putData from '../../../hook/putData'
import {getEnv} from "@urecruits/api";

const setDefaultPaymentMethod = async (cardId) => {
  const {API_RECRUITMENT} = getEnv()

  return await putData(`${API_RECRUITMENT}/api/payment/set-default-payment-method`, {
    id: cardId,
  })
}

const PaymentCreateForm = ({ usState, setRefetch, setAddedMore, setLoader, paymentMethods }) => {
  const [stripeError, setStripeError] = useState(null)
  const [paymentLengthError, setPaymentLengthError] = useState(false)

  //form data
  const [formData, setFormData] = useState({
    cardHolder: '',
    billingAddressStreet: '',
    state: '',
    city: '',
    zip: '',
    defaultCard: false,
  })

  //init stripe
  const stripe = useStripe()
  const elements = useElements()

  const onChangeInputHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }
  const {API_RECRUITMENT} = getEnv()

  const onSubmitFormHandler = async (event) => {
    event.preventDefault()
    const token: string = localStorage.getItem('token')

    if (!stripe || !elements) {
      return
    }

    if (paymentMethods.length <= 9) {
      try {
        await axios.post(`${API_RECRUITMENT}/api/payment/create-setup-intent`, {}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }).then((response) => {
          stripe.confirmCardSetup(response.data, {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: {
                address: {
                  city: formData.city,
                  state: formData.state,
                  line1: formData.billingAddressStreet,
                  postal_code: formData.zip,
                },
                name: formData.cardHolder,
              },
            },
          }).then(function (result) {
            if (result.error) {
              setStripeError(result.error)
            } else {
              setRefetch(new Date())
              if (formData.defaultCard) {
                setDefaultPaymentMethod(result.setupIntent.payment_method).then(() => {
                })
              }
              if (paymentMethods.length == 0) {
                setDefaultPaymentMethod(result.setupIntent.payment_method).then(() => {

                })
              }
              setAddedMore(false)
            }
          })
        })
      } catch (e) {
        console.log(e)
      }
    } else {
      setPaymentLengthError(true)
    }
  }

  const cancelAddedCard = (e) => {
    e.preventDefault()
    setAddedMore(false)
  }

  return (
    <form className="add-payment-method-form payment__form" onSubmit={onSubmitFormHandler}>
      <div className="payment__form__part">
        <p className="payment__form__title">
          Card information
        </p>
        <div className="payment__form__group">
          <div className="payment__form__item">
            <label className="payment__form__label">
              Card Holder
            </label>
            <input
              type="text"
              className="payment__form__input"
              name="cardHolder"
              value={formData.cardHolder ? formData.cardHolder : ''}
              placeholder="Type name of card holder"
              onChange={onChangeInputHandler}
            />
          </div>
        </div>
        <div className="payment__form__group">
          <div className={`payment__form__item half ${stripeError && stripeError.code === 'incomplete_number' ? 'error' : ''}`}>
            <label className="payment__form__label">
              Card Number
            </label>
            <div className="payment__form__stripe">
              <CardNumberElement
                options={stripeStyle}
              />
            </div>
            <p className="error-message">
              {stripeError && (stripeError.message)}
            </p>
          </div>
        </div>
        <div className="payment__form__group">
          <div
            className={`payment__form__item half ${stripeError && (stripeError.code === 'incomplete_expiry' || stripeError.code === 'invalid_expiry_year_past')
              ? 'error'
              : ''}`}>
            <label className="payment__form__label">
              Month/Year
            </label>
            <div className="payment__form__stripe">
              <CardExpiryElement
                options={stripeStyle}
              />
            </div>
            <p className="error-message">
              {stripeError && (stripeError.message)}
            </p>
          </div>
          <div className={`payment__form__item half ${stripeError && stripeError.code === 'incomplete_cvc' ? 'error' : ''}`}>
            <label className="payment__form__label">
              CVV Code
            </label>
            <div className="payment__form__stripe">
              <CardCvcElement
                options={stripeStyle}
              />
            </div>
            <p className="error-message">
              {stripeError && (stripeError.message)}
            </p>
          </div>
        </div>
      </div>
      <div className="payment__form__part">
        <p className="payment__form__title">
          Billing Address
        </p>
        <div className="payment__form__group">
          <div className="payment__form__item">
            <label className="payment__form__label">
              Billing Address Street
            </label>
            <input
              type="text"
              className="payment__form__input"
              name="billingAddressStreet"
              onChange={onChangeInputHandler}
              placeholder="Type street of billing address"
              value={formData.billingAddressStreet ? formData.billingAddressStreet : ''}
            />
          </div>
        </div>
        <div className="payment__form__group">
          <div className="payment__form__item half">
            <label className="payment__form__label">
              State
            </label>
            <ReactSelect
              options={usState}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(item: any) => {
                setFormData({ ...formData, state: item.label })
              }}
              value={formData.state ? usState.find(x => x.label === formData.state) : null}
              placeholder={'Select state'}
              styles={selectCustomStyle}
              id="state"
              instanceId="state"
            />
          </div>
          <div className="payment__form__item half">
            <label className="payment__form__label">
              City
            </label>
            <input
              type="text"
              className="payment__form__input"
              name="city"
              onChange={onChangeInputHandler}
              placeholder="Enter city"
              value={formData.city ? formData.city : ''}
            />
          </div>
        </div>
        <div className="payment__form__group">
          <div className="payment__form__item half">
            <label className="payment__form__label">
              ZIP Code
            </label>
            <input
              type="text"
              className="payment__form__input"
              name="zip"
              onChange={onChangeInputHandler}
              placeholder="Enter ZIP Code"
              value={formData.zip ? formData.zip : ''}
            />
          </div>
        </div>
        <div className="payment__form__group">
          <div className="payment__form__item default">
            <div className="payment__form__checkbox">
              {
                paymentMethods.length == 0 ?
                  <input
                    type="checkbox"
                    id="confirmCheckbox"
                    name="defaultCard"
                    checked={true}
                  />
                  : <input
                    type="checkbox"
                    id="confirmCheckbox"
                    name="defaultCard"
                    onChange={() => setFormData({ ...formData, defaultCard: !formData.defaultCard })}
                    checked={formData.defaultCard ? formData.defaultCard : false}
                  />
              }
              <label htmlFor="confirmCheckbox"><span/></label>
            </div>
            <div className="payment__form__text">
              Set as default your payment method
            </div>
          </div>
        </div>
        <div className="payment__form__group">
          <div className={`payment__form__item buttons ${(stripeError && stripeError.code === 'card_declined') || paymentLengthError ? 'error' : ''}`}>
            <div
              className="payment__form__button button--empty"
              onClick={cancelAddedCard}
            >
              Cancel
            </div>
            <button className="payment__form__button button--filled" type="submit" disabled={!stripe}>
              Save
            </button>
            <p className="error-message">
              {stripeError && (stripeError.message)}
              {paymentLengthError && ' You have exceeded the card limit. Delete a card to add a new one.'}
            </p>
          </div>
        </div>
      </div>
    </form>
  )
}

export default memo(PaymentCreateForm)