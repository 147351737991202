import {memo, useEffect, useState} from 'react'
import { default as ReactSelect } from 'react-select'
import { selectCustomStyle } from '../../../styles/selectCustomStyle'
import { selectDisableStyle } from '../../../styles/selectDisableStyle'
import putData from '../../../hook/putData'
import {getEnv} from "@urecruits/api";

const PaymentChangeForm = ({ cardData, usState, setEditMode, editMode, setRefetch, defaultCardData }) => {
  const [defaultCardError, setDefaultCardError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [onChangeStatus, setOnChangeStatus] = useState(false)

  //form data
  const [formData, setFormData] = useState({
    cardId: '',
    cardHolder: '',
    cardNumber: '',
    month: '',
    year: '',
    billingAddressStreet: '',
    state: '',
    city: '',
    zip: '',
    defaultCard: false,
  })

  //default data
  const senDefaultData = () => {
    setFormData({
      cardId: cardData.id,
      cardHolder: cardData.billing_details.name,
      cardNumber: cardData.last4,
      month: cardData.expMonth,
      year: cardData.expYear,
      billingAddressStreet: cardData.billing_details.address.line1,
      state: cardData.billing_details.address.state,
      city: cardData.billing_details.address.city,
      zip: cardData.billing_details.address.postal_code,
      defaultCard: cardData.default,
    })
  }

  //rerender set data to state
  useEffect(() => {
    if (cardData) {
      senDefaultData()
      setDefaultCardError(false)
    }
  }, [cardData])

  //set data when on change input with name
  const onChangeInputHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const onSubmitFormHandler = async (e) => {
    e.preventDefault()
    const data = {
      id: formData.cardId,
      billing_details: {
        name: formData.cardHolder,
        address: {
          city: formData.city,
          postal_code: formData.zip,
          line1: formData.billingAddressStreet,
          state: formData.state,
        },
      },
    }
    const {API_RECRUITMENT} = getEnv()

    if (editMode && onChangeStatus) {
      const res = await putData(`${API_RECRUITMENT}/api/payment/edit-payment-method`, data)
      const defaultCardRes = await putData(`${API_RECRUITMENT}/api/payment/set-default-payment-method`, {
        id: formData.cardId,
      })

      setDefaultCardError(false)
      defaultCardRes.status == 200 && res.status == 200 ? setSuccess(true) : setSuccess(false)
      setEditMode(false)
      setRefetch(new Date())
      setTimeout(() => {
        setSuccess(false)
      }, 2000)
    }
  }

  const clearFormData = () => {
    setEditMode(false)
    senDefaultData()
  }

  return (
    <form className="payment__form" onSubmit={onSubmitFormHandler} onChange={() => setOnChangeStatus(true)}>
      <div className="payment__form__part">
        <p className="payment__form__title">
          Card information
        </p>
        <div className="payment__form__group">
          <div className="payment__form__item">
            <label className="payment__form__label">
              Card Holder
            </label>
            <input
              type="text"
              className={`payment__form__input ${!editMode ? 'readonly' : ''}`}
              name="cardHolder"
              readOnly={!editMode}
              placeholder="Type name of card holder"
              value={formData.cardHolder ? formData.cardHolder : ''}
              onChange={onChangeInputHandler}
            />
          </div>
        </div>
        <div className="payment__form__group">
          <div className="payment__form__item half">
            <label className="payment__form__label">
              Card Number
            </label>
            <input
              type="text"
              className="payment__form__input readonly"
              readOnly
              value={formData.cardNumber ? `XXXX XXXX XXXX ${formData.cardNumber}` : ''}
            />
          </div>
        </div>
        <div className="payment__form__group">
          <div className="payment__form__item half">
            <label className="payment__form__label">
              Month/Year
            </label>
            <input
              type="text"
              className="payment__form__input readonly"
              readOnly
              value={formData.year && formData.month ? `${formData.month}/${formData.year}` : ''}
            />
          </div>
          <div className="payment__form__item half">
            <label className="payment__form__label">
              CVV Code
            </label>
            <input
              type="text"
              className="payment__form__input readonly"
              readOnly
              value={'***'}
            />
          </div>
        </div>
      </div>
      <div className="payment__form__part">
        <p className="payment__form__title">
          Billing Address
        </p>
        <div className="payment__form__group">
          <div className="payment__form__item">
            <label className="payment__form__label">
              Billing Address Street
            </label>
            <input
              type="text"
              className={`payment__form__input ${!editMode ? 'readonly' : ''}`}
              name="billingAddressStreet"
              onChange={onChangeInputHandler}
              placeholder="Type street of billing address"
              readOnly={!editMode}
              value={formData.billingAddressStreet ? formData.billingAddressStreet : ''}
            />
          </div>
        </div>
        <div className="payment__form__group">
          <div className="payment__form__item half">
            <label className="payment__form__label">
              State
            </label>
            <ReactSelect
              options={usState}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(item: any) => {
                setFormData({ ...formData, state: item.label })
              }}
              value={formData.state ? usState.find(x => x.label === formData.state) : null}
              placeholder={'Select state'}
              styles={!editMode ? selectDisableStyle : selectCustomStyle}
              isDisabled={!editMode}
              id="state"
              instanceId="state"
            />
          </div>
          <div className="payment__form__item half">
            <label className="payment__form__label">
              City
            </label>
            <input
              type="text"
              className={`payment__form__input ${!editMode ? 'readonly' : ''}`}
              name="city"
              readOnly={!editMode}
              placeholder="Enter city"
              onChange={onChangeInputHandler}
              value={formData.city ? formData.city : ''}
            />
          </div>
        </div>
        <div className="payment__form__group">
          <div className="payment__form__item half">
            <label className="payment__form__label">
              ZIP Code
            </label>
            <input
              type="text"
              className={`payment__form__input ${!editMode ? 'readonly' : ''}`}
              name="zip"
              readOnly={!editMode}
              onChange={onChangeInputHandler}
              placeholder="Enter ZIP Code"
              value={formData.zip ? formData.zip : ''}
            />
          </div>
        </div>
        <div className="payment__form__group">

          <div className="payment__form__item default">
            {
              cardData && !cardData.default && (
                <div className="payment__form__checkbox">
                  <input
                    type="checkbox"
                    id="confirmCheckbox"
                    name="defaultCard"
                    disabled={!editMode}
                    onChange={() => setFormData({ ...formData, defaultCard: !formData.defaultCard })}
                    checked={formData.defaultCard ? formData.defaultCard : false}
                  />
                  <label htmlFor="confirmCheckbox"><span/></label>
                </div>
              )
            }
            <div className={`payment__form__text ${cardData && cardData.default ? 'empty' : ''}`}>
              {
                cardData && !cardData.default ?
                  'Set as default your payment method'
                  : 'Selected as your default payment method'
              }
            </div>
          </div>

        </div>
        <div className="payment__form__group">
          <div className="payment__form__item buttons">
            <div
              className="payment__form__button button--empty"
              onClick={clearFormData}
            >
              Cancel
            </div>
            <button className="payment__form__button button--filled" type="submit">
              Save
            </button>
          </div>
          {success ? <p className="success-message">All changes made</p> : null}
          {defaultCardError ? <p className="error-notification">You cannot unmark this default card. Please use another card as the default card.</p> : null}
        </div>
      </div>
    </form>
  )
}

export default memo(PaymentChangeForm)